<template>
  <div>
    <h2 class="title is-2">{{ $t('subscription.subscribe') }}</h2>
    <p class="p-5">{{ $t('subscription.what_you_get')}} <b v-if="$store.getters.user.has_trial">{{ $t('subscription.days_free') }}</b></p>
    <p class="has-text-bold is-size-3 has-text-danger" v-if="cancelled">{{ $t('subscription.payment_cancelled') }}</p>


    <section>
      <p>{{ $t('subscription.monthly' )}} <b-switch v-model="billAnnually"/> {{ $t('subscription.annually' )}}</p>

      <div class="mt-5 columns is-multiline is-mobile is-centered is-3">
        <div class="column is-narrow">
          <div class="box max-width-450px">
            <h3 class="title is-3">Standard</h3>

            <p v-if="billAnnually"><span class="is-size-2 has-text-weight-bold has-text-black">49.99€</span>/ {{ $t('dates.year') }} <br/> (<span class="has-text-weight-bold">4.15€</span>/{{ $t('dates.month') }})</p>
            <p v-else><span class="is-size-2 has-text-weight-bold has-text-black">4.99€</span>/ {{ $t('dates.month') }}</p>

            <b-button size="is-large" type="is-primary" @click="subscribe('STANDARD')" v-if="$store.getters.user.account_type === 'BASE' || $store.getters.user.has_trial">
              {{ $t('subscription.subscribe_now') }}
            </b-button>
          </div>
        </div>


        <div class="column is-narrow">
          <div class="box max-width-450px">
            <h3 class="title is-3">Premium</h3>
            <p v-if="billAnnually"><span class="is-size-2 has-text-weight-bold has-text-black">119€</span>/ {{ $t('dates.year') }} <br/> (<span class="has-text-weight-bold">9.99€</span>/{{ $t('dates.month') }})</p>
            <p v-else><span class="is-size-2 has-text-weight-bold has-text-black">12.99€</span>/ {{ $t('dates.month') }}</p>

            <b-button size="is-large" type="is-primary" @click="subscribe('PREMIUM')" v-if="$store.getters.user.account_type === 'BASE' || $store.getters.user.has_trial">
              {{ $t('subscription.subscribe_now') }}
            </b-button>
          </div>
        </div>
      </div>

      <PromoCode class="mt-5"/>
    </section>

    <div class="mt-5" v-if="$store.getters.user.has_stripe_account">
      <ManageBilling/>
    </div>
  </div>
</template>

<script>
import ManageBilling from '@/components/ManageBilling.vue';
import PromoCode from '@/components/PromoCode.vue';

export default{
  name: 'subscription',
  data(){
    return{
      billAnnually: true,
      stipe: null,
    };
  },
  computed: {
    cancelled(){ return this.$route.query.cancelled == 'true'; },
    billing(){ return this.billAnnually ? 'annually' : 'monthly'; },
  },
  methods: {
    async subscribe(subType){
      try{
        let redirect = await this.post('/subscribe', { sub_type: subType, billing: this.billing });
        window.location.href = redirect;
      }catch(err){
        console.error(err);
      }
    },
  },
  components: {
    ManageBilling,
    PromoCode,
  },
};
</script>

<style lang="scss" scoped>
</style>
